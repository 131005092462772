import React from 'react'
import styled from 'styled-components/macro'
import {
  FlexColumn,
  FlexRow,
  IconContainer,
  Image,
  Logo,
  Text,
} from 'styles/generics'
import { theme } from 'styles/theme'
import volvoLogo from 'assets/images/volvo-logo.png'
import hertzLogo from 'assets/images/hertz-logo.png'
import adidasLogoSvg from 'assets/images/adidas-logo.svg'
import varietyLogo from 'assets/images/variety-logo.png'
import autoCenterLogo from 'assets/images/auto-center-logo.png'
import amramLogo from 'assets/images/amram-logo.webp'
import moveoGroupIconSvg from 'assets/images/moveo.svg'
import { Link } from 'react-router-dom'
import { footerLinks } from 'constants/footer-links'
import { socialIcons } from 'constants/social-media-links'
import { EExternalLinks } from 'types/enums/EExternalLinks'

export const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Text fontSize="24px" weight={700} color={theme.white}>
        השותפים שלנו
      </Text>
      <LogoLink to={EExternalLinks.VOLVO} width="250px" target="_blank">
        <Image src={volvoLogo} alt="volvo logo" />
      </LogoLink>
      <LogoLink to={EExternalLinks.ADIDAS} width="90px" target="_blank">
        <Image src={adidasLogoSvg} alt="adidas logo" />
      </LogoLink>
      <LogosContainer>
        <LogoRow gap={15} justify="center" pr="0">
          <LogoLink to={EExternalLinks.AMRAM} width="150px" target="_blank">
            <Image src={amramLogo} alt="amram logo" />
          </LogoLink>
          <LogoLink to={EExternalLinks.HERTZ} width="100px" target="_blank">
            <Image src={hertzLogo} alt="hertz logo" />
          </LogoLink>
          <LogoLink
            to={EExternalLinks.AUTO_CENTER}
            width="100px"
            target="_blank"
          >
            <Image src={autoCenterLogo} alt="variety logo" />
          </LogoLink>
          <LogoLink to={EExternalLinks.VARIETY} width="100px" target="_blank">
            <Image src={varietyLogo} alt="variety logo" />
          </LogoLink>
        </LogoRow>
      </LogosContainer>
      <MHFCLogoContainer>
        <Logo />
      </MHFCLogoContainer>
      <LinksRow justify="center" gap={30} width="100%">
        {footerLinks.map((link) => (
          <FooterLink key={link.text} to={link.to}>
            {link.text}
          </FooterLink>
        ))}
      </LinksRow>
      <FlexRow gap={22}>
        {socialIcons.map((icon) => (
          <IconContainer
            key={icon.alt}
            width="24px"
            href={icon.url}
            target="_blank"
          >
            <Image src={icon.src} alt={icon.alt} />
          </IconContainer>
        ))}
      </FlexRow>
      <MoveoCreditRow href={EExternalLinks.MOVEO} target="_blank">
        <MoveoGroupIconContainer align="center">
          <Image src={moveoGroupIconSvg} alt="moveo group icon" />
        </MoveoGroupIconContainer>
        <Text fontSize="12px" weight={500} color={theme.white}>
          Created by Moveo Group
        </Text>
      </MoveoCreditRow>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background-color: ${({ theme }) => theme.mhGreen};
  padding: 30px 20px;
  direction: rtl;
  color: ${theme.white};
`

const LogosContainer = styled(FlexColumn)`
  gap: 40px;
  width: 100%;
`

const LogoRow = styled(FlexRow)<{ pr?: string }>`
  ${({ pr }) => pr && `padding-right: ${pr};`}
  width: 100%;
`

const LogoLink = styled(Link)<{ width?: string }>`
  ${({ width }) => width && `width: ${width}`};
  display: flex;
  justify-content: center;
  align-items: center;
`

const MHFCLogoContainer = styled.div`
  width: 100px;
`

const LinksRow = styled(FlexRow)`
  flex-wrap: wrap;

  ${({ theme }) => theme.media.xs`
      gap: 50px;

      & > a:not(:first-child) {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          right: -25px;
          top: 50%;
          transform: translateY(-50%);
          height: 100%;
          width: 1px;
          background-color: #fff;
        }
  `}
`

const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.white};
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
`

const MoveoGroupIconContainer = styled(FlexRow)`
  width: 31px;
`

const MoveoCreditRow = styled.a`
  direction: ltr;
  display: flex;
  align-items: center;
  gap: 5px;
`
